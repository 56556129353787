import { Custom_Build_Input } from "../backend-generated-types";

export const CUSTOM_BUILD_FIELDS = `
    id
    name
    created_by {
        id
    }
    created_at
    updated_at
    usage_gaming
    usage_media_editing
    usage_software_dev
    usage_engineering
    usage_browsing
    usage_unknown
    usage_none
    purchase_date
    sold_amount
    case {
        base_name
        id
        manufacturer {
            id
            company_name
        }
        market_price
        specs
    }
    case_fans {
        base_name
        id
        manufacturer {
            id
            company_name
        }
        market_price
        specs
    }
    cpus {
        base_name
        id
        manufacturer {
            id
            company_name
        }
        market_price
        specs
    }
    cpu_coolers {
        base_name
        id
        manufacturer {
            id
            company_name
        }
        market_price
        specs
    }
    gpus {
        base_name
        id
        manufacturer {
            id
            company_name
        }
        market_price
        specs
    }
    id
    latest_appraisal_price
    motherboard {
        base_name
        id
        manufacturer {
            id
            company_name
        }
        market_price
        specs
    }
    name
    operating_systems {
        base_name
        id
        manufacturer {
            id
            company_name
        }
        market_price
        specs
    }
    psus {
        base_name
        id
        manufacturer {
            id
            company_name
        }
        market_price
        specs
    }
    rams {
        base_name
        id
        manufacturer {
            id
            company_name
        }
        market_price
        specs
    }
    storages {
        base_name
        id
        manufacturer {
            id
            company_name
        }
        market_price
        specs
    }
`;

export function build_object_to_string_helper(
    build_object: Custom_Build_Input
): string {
    return `{
        name: "${build_object.name}",
        ${build_object.cpu_ids
            ? `cpu_ids: [${build_object.cpu_ids.map((id) => '"' + id + '",')}],`
            : ""
        }
        ${build_object.gpu_ids
            ? `gpu_ids: [${build_object.gpu_ids.map((id) => '"' + id + '",')}],`
            : ""
        }
        ${build_object.psu_ids
            ? `psu_ids: [${build_object.psu_ids.map((id) => '"' + id + '",')}],`
            : ""
        }
        ${build_object.cpu_cooler_ids
            ? `cpu_cooler_ids: [${build_object.cpu_cooler_ids.map(
                (id) => '"' + id + '",'
            )}],`
            : ""
        }
        ${build_object.case_id ? `case_id: "${build_object.case_id}",` : ``}
        ${build_object.motherboard_id
            ? `motherboard_id: "${build_object.motherboard_id}",`
            : ``
        }
        ${build_object.operating_system_ids
            ? `operating_system_ids: [${build_object.operating_system_ids.map(
                (id) => '"' + id + '",'
            )}],`
            : ""
        }
        ${build_object.storage_ids
            ? `storage_ids: [${build_object.storage_ids.map(
                (id) => '"' + id + '",'
            )}],`
            : ""
        }
        ${build_object.case_fan_ids
            ? `case_fan_ids: [${build_object.case_fan_ids.map(
                (id) => '"' + id + '",'
            )}],`
            : ``
        }
        ${build_object.ram_ids
            ? `ram_ids: [${build_object.ram_ids.map((id) => '"' + id + '",')}],`
            : ``
        }
    }`;
}

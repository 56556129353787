import React from "react";
import '../../styles/main.css';
import { Appraisal_Tag } from "../../backend-generated-types";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";

type Props = {
    appraisal_tags: Appraisal_Tag[];
}

const comp_selection_style = {
}

const Appraisal_Tags = (props: Props) => {

    return (<>
        <div style={comp_selection_style}>
            <h3>{`Appraisal Tags`}</h3>


            {props.appraisal_tags ?
                <Stack direction="row" spacing={1}>
                    {props.appraisal_tags.map((t, i) =>
                        <Tooltip title={t.description} key={i}><Chip color={'primary'} label={t.label} /></Tooltip>
                    )}
                </Stack>
                : <Typography sx={{ fontStyle: 'italic' }}>No tags included on this build.</Typography>}
        </div>
    </>)
}

export default Appraisal_Tags;
import * as React from "react";
import Header_Layout from "../../components/layouts/Header_Layout";
import Desktop from "../../components/desktop/Desktop";
import Component_Read_Only from "../../components/Component_Read_Only";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import {
  Appraisal,
  Case,
  Case_Fan,
  Cpu,
  Cpu_Cooler,
  Custom_Build_Input,
  Gpu,
  Motherboard,
  Operating_System,
  Psu,
  Ram,
  Recommended_Upgrade,
  Storage,
} from "../../backend-generated-types";
import Info_Banner from "../../components/global/Info_Banner";
import { navigate } from "@reach/router";
import RecommendationsPanel from "../../components/builds/RecommendationsPanel";
import Button from "@mui/material/Button";
import LinkIcon from "@mui/icons-material/Link";
import {
  Box,
  Breadcrumbs,
  Checkbox,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Modal,
  Paper,
  Snackbar,
  SnackbarCloseReason,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { CUSTOM_BUILD_FIELDS } from "../../helpers/build_gql_helper";
import ComponentSelection from "../../components/ComponentSelection";
import PaidIcon from "@mui/icons-material/Paid";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import {
  USAGE_BROWSING_STRING,
  USAGE_ENGINEERING_STRING,
  USAGE_GAMING_STRING,
  USAGE_MEDIA_EDITING_STRING,
  USAGE_NONE_STRING,
  USAGE_SOFTWARE_DEV_STRING,
  USAGE_UNKNOWN_STRING,
} from "../../constants";
import Appraisal_Tags from "../../components/builds/AppraisalTags";
import AdvancedAnalytics from "../../components/builds/AdvancedAnalytics";
import { compact, isNil } from "lodash";
import Add_Build_Usage from "../../components/add_build/Add_Build_Usage";
import Add_Build_Basics from "../../components/add_build/Add_Build_Basics";
import dayjs, { Dayjs } from "dayjs";
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import { HeadFC, Link } from "gatsby";
import BuildIcon from "@mui/icons-material/Build";
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from "@reach/router";

const heading_styles = {
  textAlign: "left" as const,
  width: "100%",
  fontSize: "64px",
  fontFamily: "Ubuntu",
};

const flex_container_style = {
  display: "flex",
  flexDirection: "row" as const,
  flexWrap: "wrap" as const,
};

const column_style = {
  width: "50%",
};

const right_align_style = {
  textAlign: "right" as const,
  float: "right" as const,
  marginRight: 0,
  marginLeft: "auto",
};

const vertical_flex_style = {
  display: "flex",
  flexDirection: "column" as const,
  width: "100%",
};

const row_container_style = {
  display: "flex",
  flexDirection: "row" as const,
  justifyContent: "right",
};

const modal_style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  color: "text.primary",
  border: "1px solid green",
  p: 4,
  fontFamily: "Ubuntu",
};

const BuildPage = ({ params }: { params: { build_id: string, part_id: string } }) => {
  const build_id: string = params[`build_id`];
  const [latest_appraisal_price, set_latest_appraisal_price] = useState<
    string | null
  >(null);
  const [sold_amount, set_sold_amount] = useState<
    number | null
  >(null);
  const [name, set_name] = useState<string>("Untitled Build");
  const [cpus, set_cpus] = useState<Cpu[]>([]);
  const [gpus, set_gpus] = useState<Gpu[]>([]);
  const [psus, set_psus] = useState<Psu[]>([]);
  const [_case, set_case] = useState<Case | null>(null);
  const [case_fans, set_case_fans] = useState<Case_Fan[]>([]);
  const [cpu_coolers, set_cpu_coolers] = useState<Cpu_Cooler[]>([]);
  const [motherboard, set_motherboard] = useState<Motherboard | null>(null);
  const [operating_systems, set_operating_systems] = useState<
    Operating_System[]
  >([]);
  const [rams, set_rams] = useState<Ram[]>([]);
  const [storages, set_storages] = useState<Storage[]>([]);
  const [recommended_upgrades, set_recommended_upgrades] = useState<
    Recommended_Upgrade[] | null
  >(null);
  const [link_copied, set_link_copied] = useState(false);

  const [is_edit_mode, set_edit_mode] = useState(false);
  const [build_object, set_build_object] = useState<
    Custom_Build_Input | undefined
  >();
  const [cpu_ids, set_cpu_ids] = useState<string[]>([]);
  const [gpu_ids, set_gpu_ids] = useState<string[]>([]);
  const [psu_ids, set_psu_ids] = useState<string[]>([]);
  const [case_ids, set_case_ids] = useState<string[]>([]);
  const [case_fan_ids, set_case_fan_ids] = useState<string[]>([]);
  const [cpu_cooler_ids, set_cpu_cooler_ids] = useState<string[]>([]);
  const [motherboard_ids, set_motherboard_ids] = useState<string[]>([]);
  const [operating_system_ids, set_operating_system_ids] = useState<string[]>(
    []
  );
  const [ram_ids, set_ram_ids] = useState<string[]>([]);
  const [storage_ids, set_storage_ids] = useState<string[]>([]);

  const [usages, set_usages] = useState<string[] | null>([]);
  const [date_of_purchase, set_date_of_purchase] = React.useState<Dayjs | null>(
    null
  );

  const [appraise_modal_open, set_appraise_modal_open] = React.useState(false);
  const handle_appraise_modal_open = () => set_appraise_modal_open(true);
  const handle_appraise_modal_close = () => set_appraise_modal_open(false);

  const [latest_appraisal_pending, set_latest_appraisal_pending] =
    useState(false);

  const [brand_new_price, set_brand_new_price] = useState<number | undefined>();

  const parentRef = useRef<HTMLDivElement>(null);
  const [parentWidth, setParentWidth] = useState(0);
  const updateWidth = () => {
    if (parentRef.current) {
      setParentWidth(parentRef.current.offsetWidth);
    }
  };

  const location = useLocation();
  useEffect(() => {
    set_welcome_snackbar_open(typeof window !== 'undefined' && location.search.includes("welcome"));
  }, [location]);

  useEffect(() => {
    // Width calculation
    updateWidth(); // Initial width calculation
    window.addEventListener('resize', updateWidth); // Add event listener

    return () => {
      window.removeEventListener('resize', updateWidth); // Cleanup event listener
    };
  }, []);

  const {
    data: me_data,
    loading: me_loading,
  } = useQuery(
    gql`
      query {
        me {
          id
          remaining_appraisals
        }
      }
    `,
    {
      fetchPolicy: "cache-first",
    }
  );

  const { data, loading, error } = useQuery(gql`
        query Get_Custom_Build_By_Id {
            custom_build_by_id(id: "${build_id}")
            {
                ${CUSTOM_BUILD_FIELDS}
                appraisals {
                    id
                    price
                    created_at
                    updated_at
                    stale_status
                    is_completed
                    part_breakdown
                    bitbot_price
                    appraisal_tags {
                        id
                        label
                        description
                    }
                }
            }
            recommended_upgrades(custom_build_id: "${build_id}", max_result_count: 40)
            {
                id
                base_name
                component_type
                manufacturer {
                    id
                }
                market_price
                amazon_link
            }
        }
    `);

  const [
    update_build_mutation,
    {
      data: updated_build_data,
      loading: updated_build_loading,
      error: updated_build_error,
    },
  ] = useMutation(gql`
        mutation Save_Custom_Build($build: Custom_Build_Input!) {
            custom_build(build: $build, id: "${build_id}")
            {
                ${CUSTOM_BUILD_FIELDS}
                appraisals {
                    id
                    stale_status
                }
            }
        }
    `);


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function build_state_helper(build: any) {
    set_name(build.name ?? "Untitled Build");
    set_cpus(build.cpus);
    set_cpu_coolers(build.cpu_coolers);
    set_case(build.case);
    set_case_fans(build.case_fans);
    set_gpus(build.gpus);
    set_motherboard(build.motherboard);
    set_operating_systems(build.operating_systems);
    set_psus(build.psus);
    set_rams(build.rams);
    set_storages(build.storages);
    set_latest_appraisal_price(build.latest_appraisal_price);
    set_sold_amount(build.sold_amount)
  }

  function get_number_of_required_appraisals(): number {
    const number_of_parts = [
      _case ? 1 : 0,
      case_fans?.length ? case_fans.length : 0,
      cpus?.length ? cpus.length : 0,
      cpu_coolers?.length ? cpu_coolers.length : 0,
      gpus?.length ? gpus.length : 0,
      psus?.length ? psus.length : 0,
      motherboard ? 1 : 0,
      operating_systems?.length ? operating_systems.length : 0,
      rams?.length ? rams.length : 0,
      storages?.length ? storages.length : 0,
    ]
      .filter((el) => !!el)
      .reduce((p, c) => {
        return p + c;
      }, 0);
    return Math.min(number_of_parts, 3);
  }

  useEffect(() => {
    if (!updated_build_loading && !updated_build_data && data && !loading) {
      const build = data.custom_build_by_id;
      build_state_helper(build);
      set_recommended_upgrades(data.recommended_upgrades);
    }
  }, [data, loading]);

  useEffect(() => {
    const subtotals: number[] = [
      _case?.market_price ?? 0,
      compact(case_fans)
        .map((cf) => cf.market_price ?? 0)
        .reduce((p, c) => p + c, 0),
      compact(cpus)
        .map((cf) => cf.market_price ?? 0)
        .reduce((p, c) => p + c, 0),
      compact(cpu_coolers)
        .map((cf) => cf.market_price ?? 0)
        .reduce((p, c) => p + c, 0),
      compact(gpus)
        .map((cf) => cf.market_price ?? 0)
        .reduce((p, c) => p + c, 0),
      motherboard?.market_price ?? 0,
      compact(operating_systems)
        .map((cf) => cf.market_price ?? 0)
        .reduce((p, c) => p + c, 0),
      compact(psus)
        .map((cf) => cf.market_price ?? 0)
        .reduce((p, c) => p + c, 0),
      compact(rams)
        .map((cf) => cf.market_price ?? 0)
        .reduce((p, c) => p + c, 0),
      compact(storages)
        .map((cf) => cf.market_price ?? 0)
        .reduce((p, c) => p + c, 0),
    ];
    const total = subtotals.reduce((p, c) => p + c, 0) ?? undefined;
    if (
      !!total &&
      !!latest_appraisal_price &&
      total <= Number(latest_appraisal_price)
    ) {
      set_brand_new_price(undefined);
    } else {
      set_brand_new_price(total);
    }
  }, [
    _case,
    case_fans,
    cpus,
    cpu_coolers,
    gpus,
    motherboard,
    operating_systems,
    psus,
    rams,
    storages,
    latest_appraisal_price,
  ]);

  useEffect(() => {
    if (error && !data && !loading) {
      navigate("/404/");
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (!loading) {
      if (
        data.custom_build_by_id.appraisals.length === 0 ||
        data.custom_build_by_id.appraisals.at(
          data.custom_build_by_id.appraisals.length - 1
        ).is_completed
      ) {
        set_latest_appraisal_pending(false);
      } else {
        set_latest_appraisal_pending(true);
      }
    }
  }, [data, loading]);

  function copy_to_clipboard() {
    // Copy the text inside the text field
    const link = "https://www.bitlot.app/builds/" + build_id;
    navigator.clipboard.writeText(link);
    set_link_copied(true);
  }

  const [
    appraise_custom_build_mutation,
    {
      data: appraisal_data,
      loading: appraisal_loading,
    },
  ] = useMutation(
    gql`
        mutation Appraise_Custom_Build {
            appraise_custom_build(custom_build_id: "${build_id}") {
                id
                appraisals {
                    id
                    created_at
                    stale_status
                    price
                }
            }
        }
    `,
    { errorPolicy: "all" }
  );

  const [appraisal_in_progress, set_appraisal_in_progress] = useState(false);

  function appraise() {
    set_appraisal_in_progress(true);

    appraise_custom_build_mutation().then((res) => {
      if (res.data) {
        return emailjs.send(
          "service_tqw1fgb",
          "template_tl9g967",
          {
            'name': 'AUTOMATED',
            'subject': 'New Appraisal Alert',
            'message': `A new appraisal has been created for custom build ${build_id} by user ${me_data.me.id}.`
          },
          "9ekwaqSyW2kpieqLH"
        )
          .then(
            (result: { text: string }) => {
              console.log("SUCCESS!", result.text);
            },
            (error: { text: string }) => {
              console.log("FAILED...", error.text);
            }
          );
      }
    })
      .finally(() => {
        set_appraisal_in_progress(false);
        window.location.reload();
      });
  }

  function handle_save(): void {
    if (!loading && !updated_build_loading) {
      set_build_object({
        id: build_id,
        name,
        cpu_ids,
        gpu_ids,
        psu_ids,
        cpu_cooler_ids,
        case_id: case_ids?.length ? case_ids.at(0) : undefined,
        case_fan_ids,
        motherboard_id: motherboard_ids?.length
          ? motherboard_ids.at(0)
          : undefined,
        ram_ids,
        operating_system_ids,
        storage_ids,
        // purchase_date: new Date(),
        usage_browsing: usages?.includes(USAGE_BROWSING_STRING) ? 1.0 : 0.0,
        usage_engineering: usages?.includes(USAGE_ENGINEERING_STRING)
          ? 1.0
          : 0.0,
        usage_gaming: usages?.includes(USAGE_GAMING_STRING) ? 1.0 : 0.0,
        usage_media_editing: usages?.includes(USAGE_MEDIA_EDITING_STRING)
          ? 1.0
          : 0.0,
        usage_none: usages?.includes(USAGE_NONE_STRING) ? 1.0 : 0.0,
        usage_software_dev: usages?.includes(USAGE_SOFTWARE_DEV_STRING)
          ? 1.0
          : 0.0,
        usage_unknown: usages?.includes(USAGE_UNKNOWN_STRING) ? 1.0 : 0.0,
        purchase_date:
          date_of_purchase?.toISOString().substring(0, 10) ?? undefined,
      });
    }
  }

  useEffect(() => {
    if (build_object) {
      update_build_mutation({
        variables: {
          build: build_object,
        },
      }).then((res) => {
        if (res.data.custom_build) {
          const build = res.data.custom_build;
          build_state_helper(build);
        }
      });
    }
  }, [build_object]);

  useEffect(() => {
    // tODO add error outcome
    if (
      !updated_build_loading &&
      !!updated_build_data?.custom_build &&
      !updated_build_error
    ) {
      set_edit_mode(false);
    }
  }, [updated_build_data, updated_build_loading, updated_build_error]);

  const get_usage_array = (): string[] => {
    const usage_array = [];

    const gaming =
      updated_build_data?.custom_build?.usage_gaming !== undefined &&
        updated_build_data?.custom_build?.usage_gaming !== null
        ? updated_build_data.custom_build.usage_gaming > 0
        : data.custom_build_by_id.usage_gaming > 0;
    if (gaming) usage_array.push(USAGE_GAMING_STRING);

    const media =
      updated_build_data?.custom_build?.usage_media_editing !== undefined &&
        updated_build_data?.custom_build?.usage_media_editing !== null
        ? updated_build_data.custom_build.usage_media_editing > 0
        : data.custom_build_by_id.usage_media_editing > 0;
    if (media) usage_array.push(USAGE_MEDIA_EDITING_STRING);

    const development =
      updated_build_data?.custom_build?.usage_software_dev !== undefined &&
        updated_build_data?.custom_build?.usage_software_dev !== null
        ? updated_build_data.custom_build.usage_software_dev > 0
        : data.custom_build_by_id.usage_software_dev > 0;
    if (development) usage_array.push(USAGE_SOFTWARE_DEV_STRING);

    const engineering =
      updated_build_data?.custom_build?.usage_engineering !== undefined &&
        updated_build_data?.custom_build?.usage_engineering !== null
        ? updated_build_data.custom_build.usage_engineering > 0
        : data.custom_build_by_id.usage_engineering > 0;
    if (engineering) usage_array.push(USAGE_ENGINEERING_STRING);

    const browsing =
      updated_build_data?.custom_build?.usage_browsing !== undefined &&
        updated_build_data?.custom_build?.usage_browsing !== null
        ? updated_build_data.custom_build.usage_browsing > 0
        : data.custom_build_by_id.usage_browsing > 0;
    if (browsing) usage_array.push(USAGE_BROWSING_STRING);

    const unknown =
      updated_build_data?.custom_build?.usage_unknown !== undefined &&
        updated_build_data?.custom_build?.usage_unknown !== null
        ? updated_build_data.custom_build.usage_unknown > 0
        : data.custom_build_by_id.usage_unknown > 0;
    if (unknown) usage_array.push(USAGE_UNKNOWN_STRING);

    const none =
      updated_build_data?.custom_build?.usage_none !== undefined &&
        updated_build_data?.custom_build?.usage_none !== null
        ? updated_build_data.custom_build.usage_none > 0
        : data.custom_build_by_id.usage_none > 0;
    if (none) usage_array.push(USAGE_NONE_STRING);

    return usage_array;
  };

  useEffect(() => {
    if (is_edit_mode) {
      set_case_fan_ids(case_fans?.map((c) => c.id));
      set_case_ids(_case?.id ? [_case.id] : []);
      set_cpu_ids(cpus?.map((c) => c.id));
      set_gpu_ids(gpus?.map((c) => c.id));
      set_cpu_cooler_ids(cpu_coolers?.map((c) => c.id));
      set_motherboard_ids(motherboard?.id ? [motherboard.id] : []);
      set_operating_system_ids(operating_systems?.map((c) => c.id));
      set_psu_ids(psus?.map((c) => c.id));
      set_ram_ids(rams?.map((c) => c.id));
      set_storage_ids(storages?.map((c) => c.id));
      set_usages(get_usage_array());
      set_date_of_purchase(
        dayjs(
          updated_build_data?.custom_build?.purchase_date ??
          data.custom_build_by_id.purchase_date
        )
      );
    }
  }, [is_edit_mode]);

  function get_appraised_price(component_type: string): string | undefined {

    if (!data?.custom_build_by_id?.appraisals?.length || !data?.custom_build_by_id?.appraisals?.filter((app: Appraisal) => app.is_completed).length) {
      return '?'
    }

    const price = data.custom_build_by_id.appraisals.length
      ? data.custom_build_by_id.appraisals[0].part_breakdown?.filter((pb: { type: string }) => pb.type === component_type)?.map((pbs: { price: number }) => pbs.price).reduce((p: number, c: number) => p + c, 0).toFixed(2)
      : undefined

    return `$${price}`
  }

  const [welcome_snackbar_open, set_welcome_snackbar_open] = useState(false);

  const handle_snackbar_close = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    set_welcome_snackbar_open(false);
  };

  const close_snackbar = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handle_snackbar_close}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  // Due to strange IDE issues, we need to turn of type checking
  // for this overloaded toLocaleString function.
  function toUsdString(price: string): string {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return `${(price as any).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })}`
  }

  return (
    <Header_Layout>
      <Container disableGutters maxWidth={'xl'}>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb" sx={{ pt: 3, pb: 2 }}>
            <Link
              style={{ textDecoration: "none", color: 'inherit', display: 'flex', alignItems: 'center' }}
              to="/garage"
            >
              <BuildIcon sx={{ mr: 0.5 }} fontSize="inherit" color={"primary"} />
              Garage
            </Link>
            <Typography
              sx={{ color: 'text.primary', display: 'flex', alignItems: 'center' }}
            >
              <DesktopWindowsIcon sx={{ mr: 0.5 }} fontSize="inherit" color={"primary"} />
              {name}
            </Typography>
          </Breadcrumbs>
        </div>
        <div style={right_align_style}>
          {!is_edit_mode && !loading && (
            <Grid container justifyContent={'right'}>
              <Grid item>
                <Button
                  sx={{ m: 0.5 }}
                  variant={"contained"}
                  onClick={copy_to_clipboard}
                >
                  Share
                  <LinkIcon sx={{ pl: 1 }} />
                </Button>
              </Grid>
              {!me_loading &&
                me_data?.me &&
                (!latest_appraisal_pending ? (
                  <Tooltip
                    title={`This will cost ${get_number_of_required_appraisals()} appraisal(s) (one per part). You currently have ${me_data?.me?.remaining_appraisals
                      } appraisals.`}
                  >
                    <div style={{ display: "inline" }}>
                      {" "}
                      {/* wrapper div allows button to be hovered and show tooltip */}
                      <Grid item>
                        <Button
                          sx={{ m: 0.5 }}
                          disabled={
                            !isNil(sold_amount) ||
                            appraisal_data ||
                            appraisal_loading ||
                            get_number_of_required_appraisals() >
                            me_data?.me?.remaining_appraisals
                          }
                          variant={"contained"}
                          onClick={handle_appraise_modal_open}
                        >
                          Appraise
                          <PaidIcon sx={{ pl: 1 }} />
                        </Button>
                      </Grid>
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={`You have one outstanding appraisal pending processing!`}
                  >
                    <div style={{ display: "inline" }}>
                      {" "}
                      {/* wrapper div allows button to be hovered and show tooltip */}
                      <Grid>
                        <Button
                          sx={{ m: 0.5 }}
                          disabled={true}
                          variant={"contained"}
                          onClick={handle_appraise_modal_open}
                        >
                          Appraise
                          <PaidIcon sx={{ pl: 1 }} />
                        </Button>
                      </Grid>
                    </div>
                  </Tooltip>
                ))}
              {!me_loading &&
                !!me_data?.me?.id &&
                me_data.me.id === data?.custom_build_by_id?.created_by.id && (
                  <>
                    <Tooltip title="WARNING: Changing build parts will invalidate any outstanding appraisals.">
                      <Button
                        onClick={() => set_edit_mode(true)}
                        variant={"contained"}
                        aria-label="edit"
                        sx={{ float: "right", m: 0.5 }}
                        disabled={!isNil(sold_amount)}
                      >
                        Edit <EditIcon sx={{ pl: 1 }} />
                      </Button>
                    </Tooltip>
                    <Typography sx={{ width: "100%", float: "right" }}>
                      <i>
                        Created{" "}
                        {new Date(
                          data.custom_build_by_id.created_at
                        ).toLocaleString()}
                      </i>
                      <br></br>
                      {data.custom_build_by_id.updated_at !==
                        data.custom_build_by_id.created_at && (
                          <i>
                            Updated{" "}
                            {new Date(
                              data.custom_build_by_id.updated_at
                            ).toLocaleString()}
                          </i>
                        )}
                    </Typography>
                    {/* <div style={right_align_style}> */}
                    {link_copied && (
                      <div
                        style={{
                          maxWidth: 350,
                          position: "absolute",
                          float: "right" as const,
                        }}
                      >
                        <Info_Banner
                          message={"Copied shareable link to clipboard!"}
                        />
                      </div>
                    )}
                    {/* </div> */}
                  </>
                )}
            </Grid>
          )}
          {!!is_edit_mode && !loading && (
            <>
              <Button
                disabled={updated_build_loading}
                onClick={() => set_edit_mode(false)}
                variant={"contained"}
                aria-label="cancel"
                sx={{ float: "right", m: 0.5 }}
              >
                Cancel <CancelIcon sx={{ pl: 1 }} />
              </Button>
              <Tooltip title="WARNING: Changing build parts will invalidate any outstanding appraisals.">
                <Button
                  disabled={updated_build_loading}
                  onClick={() => handle_save()}
                  variant={"contained"}
                  aria-label="save"
                  sx={{ float: "right", m: 0.5 }}
                >
                  Save <SaveIcon sx={{ pl: 1 }} />
                </Button>
              </Tooltip>
            </>
          )}
        </div>
        {!is_edit_mode && (
          <>
            <div style={heading_styles}>
              <Typography variant={"h3"}>{name}</Typography>

              {!sold_amount ? ((!loading && !!latest_appraisal_price ? (
                <Tooltip
                  title={`Our estimated${brand_new_price
                    ? ` price of buying the components brand-new vs our`
                    : ``
                    } appraised valuation. Let's hear it for savings!`}
                >
                  <Box sx={{ fontSize: 20 }}>
                    <Typography
                      display="inline"
                      variant={"subtitle1"}
                      sx={{
                        fontStyle: "italic",
                        fontSize: 20,
                        textDecoration: "line-through",
                      }}
                    >
                      {`${brand_new_price
                        ? brand_new_price.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                        : ""
                        } `}
                    </Typography>
                    <Typography
                      display="inline"
                      variant={"subtitle1"}
                      sx={{ fontStyle: "italic", fontSize: 20 }}
                    >
                      {toUsdString(latest_appraisal_price)}
                    </Typography>
                  </Box>
                </Tooltip>
              ) : (
                <Typography
                  variant={"subtitle1"}
                  sx={{ fontStyle: "italic", fontSize: 20 }}
                >
                  Not yet appraised
                </Typography>
              ))) : (
                <Typography
                  variant={"subtitle1"}
                  sx={{ fontStyle: "italic", fontSize: 20 }}
                >
                  <MilitaryTechIcon />{" "}Sold for{" "}
                  {sold_amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
              )}
            </div>
            {me_data?.me?.id === data?.custom_build_by_id?.created_by?.id && (
              <Typography>
                This is your build, its parts and appraised value. Share your
                builds with others by linking them to this page.
              </Typography>
            )}
            <Grid container spacing={4}>
              <Grid item xs={12} md={8} lg={9}>
                <Grid container>
                  {!!cpus?.length && (
                    <Grid item xs={6} sm={4} md={3}>
                      <Component_Read_Only
                        return_to_build={build_id}
                        return_to_build_name={name}
                        header={"CPU"}
                        components={cpus}
                        component_type="cpu"
                        appraised_price={get_appraised_price('cpu')}
                      />
                    </Grid>
                  )}
                  {!!cpu_coolers?.length && (
                    <Grid item xs={6} sm={4} md={3}>
                      <Component_Read_Only
                        return_to_build={build_id}
                        return_to_build_name={name}
                        header={"CPU Cooler"}
                        components={cpu_coolers}
                        component_type="cpu_cooler"
                        appraised_price={get_appraised_price('cpu_cooler')}
                      />
                    </Grid>
                  )}
                  {!!gpus?.length && (
                    <Grid item xs={6} sm={4} md={3}>
                      <Component_Read_Only
                        return_to_build={build_id}
                        return_to_build_name={name}
                        header={"GPU"}
                        components={gpus}
                        component_type="gpu"
                        appraised_price={get_appraised_price('gpu')}
                      />
                    </Grid>
                  )}
                  {!!motherboard && (
                    <Grid item xs={6} sm={4} md={3}>
                      <Component_Read_Only
                        return_to_build={build_id}
                        return_to_build_name={name}
                        header={"Motherboard"}
                        components={motherboard ? [motherboard] : undefined}
                        component_type="motherboard"
                        appraised_price={get_appraised_price('motherboard')}
                      />
                    </Grid>
                  )}
                  {!!psus?.length && (
                    <Grid item xs={6} sm={4} md={3}>
                      <Component_Read_Only
                        return_to_build={build_id}
                        return_to_build_name={name}
                        header={"Power Supply"}
                        component_type="psu"
                        components={psus}
                        appraised_price={get_appraised_price('psu')}
                      />
                    </Grid>
                  )}
                  {!!_case && (
                    <Grid item xs={6} sm={4} md={3}>
                      <Component_Read_Only
                        return_to_build={build_id}
                        return_to_build_name={name}
                        header={"Case"}
                        component_type="case"
                        components={_case ? [_case] : undefined}
                        appraised_price={get_appraised_price('case')}
                      />
                    </Grid>
                  )}
                  {!!storages?.length && (
                    <Grid item xs={6} sm={4} md={3}>
                      <Component_Read_Only
                        return_to_build={build_id}
                        return_to_build_name={name}
                        header={"Hard Drive"}
                        component_type="storage"
                        components={storages}
                        appraised_price={get_appraised_price('storage')}
                      />
                    </Grid>
                  )}
                  {!!case_fans?.length && (
                    <Grid item xs={6} sm={4} md={3}>
                      <Component_Read_Only
                        return_to_build={build_id}
                        return_to_build_name={name}
                        header={"Case Fan"}
                        component_type="case_fan"
                        components={case_fans}
                        appraised_price={get_appraised_price('case_fan')}
                      />
                    </Grid>
                  )}
                  {!!operating_systems?.length && (
                    <Grid item xs={6} sm={4} md={3}>
                      <Component_Read_Only
                        return_to_build={build_id}
                        return_to_build_name={name}
                        header={"Operating System"}
                        component_type="operating_system"
                        components={operating_systems}
                        appraised_price={get_appraised_price('operating_system')}
                      />
                    </Grid>
                  )}
                  {!!rams?.length && (
                    <Grid item xs={6} sm={4} md={3}>
                      <Component_Read_Only
                        return_to_build={build_id}
                        return_to_build_name={name}
                        header={"RAM"}
                        component_type="ram"
                        components={rams}
                        appraised_price={get_appraised_price('ram')}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <Grid container direction={"column"} spacing={1} ref={parentRef}>
                  <Grid item sx={{ maxWidth: parentWidth }}
                    display={{ xs: "none", sm: "flex", md: "flex", lg: "flex" }}>
                    <Desktop
                      is_static={false}
                      case_enabled={!!_case?.id}
                      cpu_enabled={!!cpus?.length}
                      cpu_cooler_enabled={!!cpu_coolers?.length}
                      gpu_enabled={!!gpus?.length}
                      motherboard_enabled={!!motherboard?.id}
                      operating_system_enabled={!!operating_systems?.length}
                      psu_enabled={!!psus?.length}
                      ram_enabled={!!rams?.length}
                      storage_enabled={!!storages?.length}
                      case_fan_enabled={!!case_fans?.length}
                      scale_percentage={parentWidth / 600 * 100}
                    />
                  </Grid>
                  <Grid item>
                    <Paper>
                      <List
                        sx={{ width: "100%", bgcolor: "background.paper" }}
                        aria-label="appraisals"
                        dense
                        subheader={
                          <ListSubheader
                            component="h1"
                            id="nested-list-subheader"
                          >
                            Appraisal history
                          </ListSubheader>
                        }
                      >
                        {(data?.custom_build_by_id?.appraisals as Appraisal[] ?? []).map(
                          (app, i) => (
                            <div key={i}>
                              {!app.is_completed && (
                                <ListItem>
                                  <ListItemButton>
                                    <ListItemIcon />
                                    <Typography variant="h3">
                                      <ListItemText
                                        primary={`Pending`}
                                        secondary={`Submitted ${new Date(
                                          app.created_at
                                        ).toLocaleString()}. Submission pending completion on our end.`}
                                      />
                                    </Typography>
                                    <ListItemText primary={``} />
                                  </ListItemButton>
                                </ListItem>
                              )}
                              {app.is_completed && !app.stale_status && (
                                <ListItem>
                                  <ListItemButton>
                                    <ListItemIcon>
                                      <Tooltip title="This appraisal is valid!">
                                        <PriceCheckIcon />
                                      </Tooltip>
                                    </ListItemIcon>
                                    <Typography variant="h3">
                                      <ListItemText
                                        primary={`${app.price.toLocaleString(
                                          "en-US",
                                          { style: "currency", currency: "USD" }
                                        )}`}
                                        secondary={`Appraised ${new Date(
                                          app.updated_at
                                        ).toLocaleString()}`}
                                      />
                                    </Typography>
                                  </ListItemButton>
                                </ListItem>
                              )}
                              {app.is_completed && !!app.stale_status && (
                                <ListItem>
                                  <ListItemButton>
                                    <ListItemIcon />
                                    <ListItemText
                                      primary={`${app.price.toLocaleString(
                                        "en-US",
                                        { style: "currency", currency: "USD" }
                                      )}`}
                                      secondary={`Created ${new Date(
                                        app.updated_at
                                      ).toLocaleString()}`}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              )}
                            </div>
                          )
                        )}
                        {!data?.custom_build_by_id?.appraisals?.length && (
                          <ListItem>
                            <ListItemText>
                              <Typography
                                variant="body2"
                                sx={{ fontStyle: "italic" }}
                              >
                                No appraisals yet.
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        )}
                      </List>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper sx={{ width: "100%" }}>
                      <List
                        sx={{ bgcolor: "background.paper" }}
                        aria-label="usage-history"
                        dense
                        subheader={
                          <ListSubheader
                            component="h1"
                            id="nested-list-subheader-usage"
                            sx={{ width: '100%' }}
                          >
                            {`Usage history${(updated_build_data?.custom_build?.purchase_date !==
                              null &&
                              updated_build_data?.custom_build
                                ?.purchase_date !== undefined) ||
                              (data?.custom_build_by_id?.purchase_date !== null &&
                                data?.custom_build_by_id?.purchase_date !==
                                undefined)
                              ? ` - Purchased ${new Date(
                                updated_build_data?.custom_build
                                  ?.purchase_date ??
                                data.custom_build_by_id.purchase_date
                              ).toLocaleDateString()}`
                              : ``
                              }`}
                          </ListSubheader>
                        }
                      >
                        <ListItem>
                          <ListItemText>Gaming</ListItemText>
                          <Checkbox
                            checked={
                              updated_build_data?.custom_build?.usage_gaming !==
                                undefined &&
                                updated_build_data?.custom_build?.usage_gaming !==
                                null
                                ? updated_build_data?.custom_build?.usage_gaming > 0
                                : data?.custom_build_by_id?.usage_gaming > 0
                            }
                            disabled
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText>Media editing</ListItemText>
                          <Checkbox
                            checked={
                              updated_build_data?.custom_build
                                ?.usage_media_editing !== undefined &&
                                updated_build_data?.custom_build
                                  ?.usage_media_editing !== null
                                ? updated_build_data?.custom_build?.usage_media_editing > 0
                                : data?.custom_build_by_id?.usage_media_editing > 0
                            }
                            disabled
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText>Software development</ListItemText>
                          <Checkbox
                            checked={
                              updated_build_data?.custom_build
                                ?.usage_software_dev !== undefined &&
                                updated_build_data?.custom_build
                                  ?.usage_software_dev !== null
                                ? updated_build_data?.custom_build?.usage_software_dev > 0
                                : data?.custom_build_by_id?.usage_software_dev > 0
                            }
                            disabled
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText>Engineering</ListItemText>
                          <Checkbox
                            checked={
                              updated_build_data?.custom_build
                                ?.usage_engineering !== undefined &&
                                updated_build_data?.custom_build
                                  ?.usage_engineering !== null
                                ? updated_build_data?.custom_build?.usage_engineering > 0
                                : data?.custom_build_by_id?.usage_engineering > 0
                            }
                            disabled
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText>Browsing</ListItemText>
                          <Checkbox
                            checked={
                              updated_build_data?.custom_build?.usage_browsing !==
                                undefined &&
                                updated_build_data?.custom_build?.usage_browsing !==
                                null
                                ? updated_build_data?.custom_build?.usage_browsing >
                                0
                                : data?.custom_build_by_id?.usage_browsing > 0
                            }
                            disabled
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText>Unknown</ListItemText>
                          <Checkbox
                            checked={
                              updated_build_data?.custom_build?.usage_unknown !==
                                undefined &&
                                updated_build_data?.custom_build?.usage_unknown !==
                                null
                                ? updated_build_data?.custom_build?.usage_unknown >
                                0
                                : data?.custom_build_by_id?.usage_unknown > 0
                            }
                            disabled
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText>None</ListItemText>
                          <Checkbox
                            checked={
                              updated_build_data?.custom_build?.usage_none !==
                                undefined &&
                                updated_build_data?.custom_build?.usage_none !==
                                null
                                ? updated_build_data?.custom_build?.usage_none > 0
                                : data?.custom_build_by_id?.usage_none > 0
                            }
                            disabled
                          />
                        </ListItem>
                      </List>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!loading && (<Grid container mt={4}>
              <Grid item md={6} sm={12}>
                {latest_appraisal_price ? (
                  <Typography>
                    Last appraised at{" "}
                    <b>
                      {toUsdString(latest_appraisal_price)}
                    </b>
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            )}
            <div style={vertical_flex_style}>
              <div style={right_align_style}>
                {!loading &&
                  !!data?.custom_build_by_id?.appraisals?.length &&
                  !!latest_appraisal_price && (
                    <Appraisal_Tags
                      appraisal_tags={
                        data.custom_build_by_id.appraisals.at(
                          data.custom_build_by_id.appraisals.length - 1
                        ).appraisal_tags
                      }
                    // appraisal_tags={[ // for testing
                    //     {id: 'ex', label: 'example of a long tag', description: 'ex'},
                    //     {id: 'ex', label: 'example of a long tag', description: 'ex'},
                    //     {id: 'ex', label: 'example of a long tag', description: 'ex'},
                    //     {id: 'ex', label: 'example of a long tag', description: 'ex'},
                    //     {id: 'ex', label: 'example of a long tag', description: 'ex'},
                    //     {id: 'ex', label: 'example of a long tag', description: 'ex'},
                    //     {id: 'ex', label: 'example of a long tag', description: 'ex'},
                    // ]}
                    />
                  )}
              </div>
            </div>
            {!loading && !!data?.custom_build_by_id && (
              <AdvancedAnalytics
                custom_build_id={build_id}
                appraisal={
                  data.custom_build_by_id.appraisals.length
                    ? data.custom_build_by_id.appraisals[0]
                    : undefined
                }
                handle_appraise_modal_open={
                  !(
                    appraisal_data ||
                    appraisal_loading ||
                    get_number_of_required_appraisals() >
                    me_data?.me?.remaining_appraisals
                  ) ? handle_appraise_modal_open : undefined
                }
              />
            )}

            {!me_loading &&
              !!me_data?.me &&
              !loading &&
              !!data?.custom_build_by_id &&
              me_data.me.id === data.custom_build_by_id.created_by.id &&
              !!recommended_upgrades?.length && (
                <Grid container display={{ xs: 'none', md: 'flex' }}>
                  <RecommendationsPanel
                    recommendations={recommended_upgrades ?? []}
                  />
                </Grid>
              )}
          </>
        )}
        {is_edit_mode && (
          <>
            <Typography variant={"h3"}>{name}</Typography>
            <div style={flex_container_style}>
              <div style={column_style}>
                <div style={flex_container_style}>
                  <ComponentSelection
                    name={"CPU"}
                    query_type="cpu"
                    placeholder={"Intel Core i9"}
                    value_handler={set_cpu_ids}
                    enable_multiple={true}
                    default_values={cpus?.map((c) => {
                      return { key: c.id, label: c.base_name };
                    })}
                  />
                  <ComponentSelection
                    name={"CPU Cooler"}
                    query_type="cpu_cooler"
                    placeholder={"Noctua NH-L9i"}
                    value_handler={set_cpu_cooler_ids}
                    enable_multiple={true}
                    default_values={cpu_coolers?.map((c) => {
                      return { key: c.id, label: c.base_name };
                    })}
                  />
                  <ComponentSelection
                    name={"GPU"}
                    query_type="gpu"
                    placeholder={"RTX 2070"}
                    value_handler={set_gpu_ids}
                    enable_multiple={true}
                    default_values={gpus?.map((c) => {
                      return { key: c.id, label: c.base_name };
                    })}
                  />
                  <ComponentSelection
                    name={"Motherboard"}
                    query_type="motherboard"
                    placeholder={"EVGA Z790"}
                    value_handler={set_motherboard_ids}
                    default_values={
                      motherboard
                        ? [
                          {
                            key: motherboard.id,
                            label: motherboard?.base_name,
                          },
                        ]
                        : undefined
                    }
                  />
                  <ComponentSelection
                    name={"Power Supply"}
                    query_type="psu"
                    placeholder={"Rosewill CFZ"}
                    value_handler={set_psu_ids}
                    enable_multiple={true}
                    default_values={psus?.map((c) => {
                      return { key: c.id, label: c.base_name };
                    })}
                  />
                  <ComponentSelection
                    name={"Case"}
                    query_type="case"
                    placeholder={"NZXT H710"}
                    value_handler={set_case_ids}
                    default_values={
                      _case
                        ? [{ key: _case.id, label: _case?.base_name }]
                        : undefined
                    }
                  />
                  <ComponentSelection
                    name={"Hard Drive"}
                    query_type="storage"
                    placeholder={"Western Digital Blue"}
                    value_handler={set_storage_ids}
                    enable_multiple={true}
                    default_values={storages?.map((c) => {
                      return { key: c.id, label: c.base_name };
                    })}
                  />
                  <ComponentSelection
                    name={"Case Fan"}
                    query_type="case_fan"
                    placeholder={"Corsair ML Elite"}
                    value_handler={set_case_fan_ids}
                    enable_multiple={true}
                    default_values={case_fans?.map((c) => {
                      return { key: c.id, label: c.base_name };
                    })}
                  />
                  <ComponentSelection
                    name={"Operating System"}
                    query_type="operating_system"
                    placeholder={"Windows 11 Home"}
                    value_handler={set_operating_system_ids}
                    enable_multiple={true}
                    default_values={operating_systems?.map((c) => {
                      return { key: c.id, label: c.base_name };
                    })}
                  />
                  <ComponentSelection
                    name={"RAM"}
                    query_type="ram"
                    placeholder={"G.Skill Aegis 16 GB"}
                    value_handler={set_ram_ids}
                    enable_multiple={true}
                    default_values={rams?.map((c) => {
                      return { key: c.id, label: c.base_name };
                    })}
                  />
                </div>
              </div>
              <div style={column_style}>
                <Desktop
                  is_static={false}
                  case_enabled={!!_case?.id}
                  cpu_enabled={!!cpus?.length}
                  cpu_cooler_enabled={!!cpu_coolers?.length}
                  gpu_enabled={!!gpus?.length}
                  motherboard_enabled={!!motherboard?.id}
                  operating_system_enabled={!!operating_systems?.length}
                  psu_enabled={!!psus?.length}
                  ram_enabled={!!rams?.length}
                  storage_enabled={!!storages?.length}
                  case_fan_enabled={!!case_fans?.length}
                />
                <Typography
                  fontStyle={"italic"}
                  fontSize={"small"}
                  sx={{ textAlign: "right" }}
                >
                  * Rendering for artistic purposes only; not accurate to
                  product.
                </Typography>
                <br></br>
              </div>
            </div>
            <Add_Build_Usage
              value_handler={set_usages}
              exclude_creation_step_flow={true}
              existing_values={usages ?? undefined}
            />
            <Add_Build_Basics
              value_handler={set_date_of_purchase}
              exclude_creation_step_flow={true}
              existing_value={date_of_purchase ?? undefined}
            />
          </>
        )}
        <Snackbar
          open={welcome_snackbar_open}
          autoHideDuration={5000}
          message="Thanks for telling us about your build! Here you can manage and edit your build, as well as get it appraised."
          action={close_snackbar}
        />
        <Modal
          open={appraise_modal_open}
          onClose={handle_appraise_modal_close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modal_style}>
            <Info_Banner
              title={"Appraise build?"}
              message={`This will cost ${get_number_of_required_appraisals()} appraisal(s) (one per part). You currently have ${me_data?.me?.remaining_appraisals
                } appraisals.`}
            />
            <div style={row_container_style}>
              <Button
                type={"submit"}
                variant={"contained"}
                onClick={handle_appraise_modal_close}
                sx={{ my: 2, display: "block", marginRight: "10px" }}
                disabled={appraisal_loading || appraisal_in_progress}
              >
                {"Cancel"}
              </Button>
              <Button
                type={"submit"}
                variant={"outlined"}
                onClick={appraise}
                sx={{ my: 2, display: "block" }}
                disabled={appraisal_loading || appraisal_in_progress}
              >
                Appraise
              </Button>
            </div>
          </Box>
        </Modal>
      </Container>
    </Header_Layout >
  );
};

export default BuildPage;

export const Head: HeadFC = () => { return <title>Build - BitLot.app</title> }